import React, { useEffect } from 'react';
import Logo from '../../assets/svgs/logo.svg';
import Input from '../../components/forms/Input';
import Button from '../../components/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { addUserToLocalStorage } from '../../utils/localstorage';
import { useDispatch } from 'react-redux';
import { signupEmployee } from '../../features/employees/employeesSlice';
// import { useNavigate } from 'react-router-dom';

const EmployeeSignUp = () => {
	const [upperCase, setUpperCase] = useState(false);
	const [lowerCase, setLowerCase] = useState(false);
	const [special, setSpecial] = useState(false);
	const [length, setLength] = useState(false);
	const [isPasswordField, setIsPasswordField] = useState(false);
	const [userData, setUserData] = useState({});

	// const { addEmployeeLoading, employeeSignUp } = useSelector(
	// 	(store) => store.employee
	// );

	const dispatch = useDispatch();

	const verifyEmployee = async () => {
		const searchParams = location.search;
		const token = searchParams.split('?')[1];
		if (token) {
			try {
				const response = await axios.put(
					`${process.env.REACT_APP_BASE_URL}business/auth/employee/status?token=${token}`
					// {
					// 	headers: {
					// 		Authorization: `Bearer ${token}`,
					// 		Accept: 'application/json',
					// 		'Content-Type': 'application/json',
					// 	},
					// }
				);
				if (response.status === 200) {
					notify();
					const user = {
						token: response?.data?.data?.token,
					};
					addUserToLocalStorage(user);
					setUserData(response?.data);
				}
			} catch (error) {
				notifyError(error?.response?.data?.message);
			}
		} else {
			notifyError('Token not found');
		}
	};

	useEffect(() => {
		verifyEmployee();
	}, [location.search]);

	const phoneRegExp =
		/((^090)([0-9]))|((^070)([0-9]))|((^080)([0-9]))|((^091)([0-9]))|((^071)([0-9]))|((^081)([0-9]))(\d{7})/;
	const schema = Yup.object({
		// firstname: Yup.string().required('First name is required'),
		// lastname: Yup.string().required('Last name is required'),
		phone: Yup.string()
			.required('Phone number is required')
			.matches(phoneRegExp, 'Phone number is not valid')
			.min(11, 'Phone number cannot be less than 11 characters')
			.max(14, 'Phone number cannot be longer than 14 characters'),
		email: Yup.string()
			// .required('Email field is required')
			.email('Invalid email address'),
		password: Yup.string()
			.required('Required')
			.min(8, 'Password must be at least 8 characters')
			.matches(
				/^(?=.*[a-z])/,
				'Password must contain at least one lowercase letter'
			)
			.matches(
				/^(?=.*[A-Z])/,
				'Password must contain at least one uppercase letter'
			)
			.matches(/^(?=.*[!@#$%^&*_-])/, 'Must Contain One Special Character'),
	});
	const { handleSubmit, handleChange, handleBlur, touched, values, errors } =
		useFormik({
			initialValues: {
				password: '',
				firstname: '',
				lastname: '',
				phone: '',
				email: '',
			},
			validationSchema: schema,
			onSubmit: (values) => {
				dispatch(
					signupEmployee({
						firstname: values?.firstname,
						lastname: values?.lastname,
						password: values?.password,
						phone: values?.phone,
						// email: values?.username,
					})
				);
			},
		});

	useEffect(() => {
		if (values.password) {
			setUpperCase(/[A-Z]/g.test(values.password));
			setLowerCase(/[a-z]/g.test(values.password));
			setSpecial(/[!@#$%^&*_-]/g.test(values.password));
			setLength(values.password.length >= 8);
		}
		if (values.password === '') {
			setUpperCase(false);
			setLowerCase(false);
			setSpecial(false);
			setLength(false);
		}
	}, [values.password]);

	const notify = () => toast.success('Email verification successful!!! 🎉🎉');
	const notifyError = (message) => toast.error(message);
	return (
		<div className="empoyeeSignUp">
			<div className="brand">
				<img src={Logo} alt="Flance Logo" />
			</div>
			<div className="employeeSignUp__container">
				<div className="employee__form">
					<div className="auth__form--header">
						<div className="employee--content">
							<h3>Join your team to unlock your benefits!</h3>
							<p>Please, fill in your details to create your Flance account</p>
						</div>
					</div>
					<form className=" __form-employeeSignup" onSubmit={handleSubmit}>
						<div className="inputgroup">
							<Input
								type="text"
								id="firstname"
								label="First name"
								name="firstname"
								value={values?.firstname}
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder="First name"
								// disabled
								error={
									touched.firstname && errors.firstname ? (
										<small className="error">{errors.firstname}</small>
									) : null
								}
							/>
							<Input
								type="text"
								id="lastname"
								label="Last name"
								name="lastname"
								value={values?.lastname}
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder="Last name"
								// disabled
								error={
									touched.lastname && errors.lastname ? (
										<small className="error">{errors.lastname}</small>
									) : null
								}
							/>
						</div>
						<Input
							type="email"
							id="email"
							label="Email address"
							name="email"
							value={userData?.data?.email}
							onChange={handleChange}
							onBlur={handleBlur}
							disabled
						/>
						<Input
							type="tel"
							id="phone"
							label="Phone number"
							name="phone"
							value={values.phone}
							onChange={handleChange}
							onBlur={handleBlur}
							error={
								touched.phone && errors.phone ? (
									<small className="error">{errors.phone}</small>
								) : null
							}
						/>
						<Input
							type="password"
							id="password"
							placeholder="Create Password"
							label="Create Password"
							name="password"
							value={values.password}
							onChange={handleChange}
							onBlur={handleBlur}
							onFocus={() => setIsPasswordField(true)}
							error={
								touched.password && errors.password ? (
									<small className="error">{errors.password}</small>
								) : null
							}
						/>
						{isPasswordField && (
							<div className="password-rules">
								<div className="lineIndicator">
									<div className={`div ${length ? 'passed' : ''}`}></div>
									<div className={`div ${upperCase ? 'passed' : ''}`}></div>
									<div className={`div ${lowerCase ? 'passed' : ''}`}></div>
									<div className={`div ${special ? 'passed' : ''}`}></div>
								</div>
								<div className="actualRules">
									<div className={`rule ${length ? 'active' : ''}`}>
										<div className="circle"></div>
										<p>8 or more characters</p>
									</div>
									<div className={`rule ${upperCase ? 'active' : ''}`}>
										<div className="circle"></div>
										<p>An uppercase letter</p>
									</div>
									<div className={`rule  ${lowerCase ? 'active' : ''}`}>
										<div className="circle"></div>
										<p>A lowercase letter</p>
									</div>
									<div className={`rule  ${special ? 'active' : ''}`}>
										<div className="circle "></div>
										<p>A symbol (=!@#&$%)</p>
									</div>
								</div>
							</div>
						)}

						<Button
							variant="primary"
							type="submit"
							className={'mt-40'}
							block
							// disabled={isLoading}
						>
							{/* {isLoading ? 'Loading...' : 'Create account'} */}
							Create account
						</Button>
					</form>

					<p className="mt-32 tx-c acknoledgment">
						By clicking “Create Account”, you acknowledge that you have read and
						understood, and agreed to our{' '}
						<a
							className=""
							href="https://www.flanceapp.com/terms"
							target={`_blank`}
						>
							Terms of Use
						</a>{' '}
						and{' '}
						<a
							className=""
							href="https://www.flanceapp.com/privacy"
							target={`blank`}
						>
							Privacy Policy
						</a>
						.
					</p>
				</div>
			</div>
		</div>
	);
};

export default EmployeeSignUp;
