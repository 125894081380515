import React from 'react';
import Thead from './Thead';

const Table = ({
	columns,
	children,
	blueHeader = false,
	transparentHeader = false,
}) => {
	return (
		<table>
			<Thead
				columns={columns}
				blueHeader={blueHeader}
				transparentHeader={transparentHeader}
			/>
			{children}
		</table>
	);
};

export default Table;
