import React from 'react';

const Thead = ({ columns, blueHeader = false, transparentHeader = false }) => {
	return (
		<tr>
			{columns.map((column, index) => (
				<th
					key={index}
					className={`${blueHeader ? 'blueHeader' : ''} ${
						transparentHeader ? 'transparentHeader' : ''
					}`}
				>
					{column}
				</th>
			))}
		</tr>
	);
};

export default Thead;
