import React, { useEffect, useState } from 'react';
import Goback from '../../../components/Goback';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import SearchAddContainer from '../../../components/SearchAddContainer';
import ClientsTable from '../../../components/table/ClientsTable';
import ClientsDetailSideModal from '../../../components/modals/ClientsDetailSideModal';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAllRessellersClients,
	getResellablePass,
	resellerClientsByPass,
} from '../../../features/resellers/resellersSlice';
import Spinner from '../../../components/PageLoader';
import { paginate } from '../../../utils/helpers';
import Pagination from '../../../components/Pagination';

const Clients = () => {
	const navigate = useNavigate();
	const [selectedFilter, setSelectedFilter] = useState('');
	const [userSearch, setUserSearch] = useState('');
	const [openSideModal, setOpenSideModal] = useState(false);
	const [selectedClient, setSelectedClient] = useState({});

	const [paginateClients, setPaginateClients] = useState([]);
	const [paginatedClients, setPaginatedClients] = useState([]);
	const [page, setPage] = useState(0);

	const { resellersClients, submitingFrom, resellerPasses } = useSelector(
		(store) => store.resellers
	);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getAllRessellersClients());
		dispatch(getResellablePass());
	}, []);
	const allClients = resellersClients?.content || resellersClients?.data || [];

	useEffect(() => {
		if (selectedFilter?.id) {
			if (selectedFilter?.id === 'all') {
				dispatch(getAllRessellersClients());
			} else {
				dispatch(resellerClientsByPass(selectedFilter?.id));
			}
		}
	}, [selectedFilter]);

	const clinetsNameFilter = allClients.filter((user) => {
		if (
			user?.firstName?.toLowerCase().includes(userSearch.toLowerCase()) ||
			user?.lastName?.toLowerCase().includes(userSearch.toLowerCase())
		) {
			return user;
		}
	});

	useEffect(() => {
		if (clinetsNameFilter) {
			setPaginateClients(paginate(clinetsNameFilter, 7));
		}
	}, [clinetsNameFilter?.length]);

	useEffect(() => {
		if (paginateClients) {
			setPaginatedClients(paginateClients[page]);
		}
	}, [paginateClients, page]);

	return (
		<>
			{submitingFrom ? (
				<>
					<Spinner />
				</>
			) : (
				<>
					{' '}
					<div className="clients">
						<Goback linkTo={'/enterprise'} />
						<div className="clients__header header--btn">
							<div>
								<h4>Clients</h4>
								<p className="mini-text">
									Manage your client list and assign vouchers
								</p>
							</div>
							<div className="enterprise__header--btn">
								<Button
									variant={'primary'}
									size={'md'}
									onClick={() => {
										navigate('/add-clients');
									}}
								>
									<span className="add">+</span>
									Add New Clients
								</Button>
							</div>
						</div>
						<div className="clients__table mt-16">
							<div className="clients__table--header">
								<h4>Client Information</h4>
								<SearchAddContainer
									changeInput={(e) => setUserSearch(e.target.value)}
									filterOptions={resellerPasses?.data?.map((item) => {
										return { name: item?.name, id: item?.id };
									})}
									selectedFilter={selectedFilter}
									setSelectedFilter={setSelectedFilter}
									flipSides={true}
									isFilterObject={true}
									addAllfilter={true}
								/>
							</div>
							<div className="clients__table--body mt-24">
								<ClientsTable
									setOpenSideModal={setOpenSideModal}
									data={paginatedClients}
									setSelectedClient={setSelectedClient}
								/>
								<Pagination
									employees={paginateClients}
									page={page}
									setPage={setPage}
								/>
							</div>
						</div>
						{openSideModal && (
							<ClientsDetailSideModal
								openSideModal={openSideModal}
								setOpenSideModal={setOpenSideModal}
								selectedClient={selectedClient}
							/>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default Clients;
