import React from 'react';
import Table from './Table';

export const ErrorTable = ({ data }) => {
	const columns = ['First Name', 'Last Name', 'Email', 'Phone number'];
	return (
		<Table columns={columns} blueHeader={true} transparentHeader={true}>
			{data?.map((row, index) => (
				<tr className="pointer grey-bg" key={index}>
					<td
						className={`plan-table ${
							row?.firstName.includes('Mandatory') ? 'errText' : ''
						} `}
					>
						{row?.firstName}
					</td>
					<td
						className={`plan-table ${
							row?.lastName.includes('Mandatory') ? 'errText' : ''
						} `}
					>
						{row?.lastName}
					</td>
					<td
						className={`plan-table ${
							row?.email.includes('Mandatory') ? 'errText' : ''
						} `}
					>
						{row?.email || '--'}
					</td>
					<td
						className={`plan-table ${
							row?.phone.includes('Mandatory') ? 'errText' : ''
						} `}
					>
						{row?.phone || '--'}
					</td>
				</tr>
			))}
		</Table>
	);
};
