import React, { useEffect } from 'react';
import AuthStatus from '../../components/AuthStatus';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const ExisitingUserVerify = () => {
	const location = useLocation();

	const verifyEmployee = async () => {
		const searchParams = location.search;
		const token = searchParams.split('?')[1];
		if (token) {
			try {
				const response = await axios.put(
					`${process.env.REACT_APP_BASE_URL}business/auth/employee/status?token=${token}`
				);
				if (response.status === 200) {
					notify();
					setTimeout(() => {
						window.location.href = `${process.env.REACT_APP_FLANCE_APP_URL}login`;
					}, 1000);
				}
			} catch (error) {
				notifyError(error?.response?.data?.message);
			}
		} else {
			notifyError('Token not found');
		}
	};

	useEffect(() => {
		verifyEmployee();
	}, [location.search]);
	const notify = () => toast.success('Email verification successful!!! 🎉🎉');
	const notifyError = () => toast.error('Email verification failed!!!');
	return (
		<>
			<AuthStatus
				authDetails={'Please wait, this action will take a few seconds'}
				authStatus={'Verifying your email'}
				icon={'mailbox'}
			/>
		</>
	);
};

export default ExisitingUserVerify;
