import React, { useEffect, useState } from 'react';
import { Modal } from '../Modal';
import Input from '../forms/Input';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEmployee } from '../../features/employees/employeesSlice';

const DeleteEmployeeModal = ({
	title,
	show,
	setDeleteEmployeeModal,
	currentEmployee,
	setRemoveEmployee,
}) => {
	const [name, setName] = useState('');
	const dispatch = useDispatch();
	const { addEmployeeLoading, deleteEmployeeSuccess } = useSelector(
		(store) => store.employees
	);
	useEffect(() => {
		if (deleteEmployeeSuccess) {
			setName('');
			setDeleteEmployeeModal(false);
			setRemoveEmployee(true);
		}
	}, [deleteEmployeeSuccess]);
	return (
		<Modal
			title={title}
			show={show}
			handleClose={() => {
				setName('');
				setDeleteEmployeeModal(false);
			}}
		>
			<div className="modal__content">
				<p className="main-text">
					Deleting{' '}
					<b>{`${currentEmployee?.firstname} ${currentEmployee?.lastname}`}</b>{' '}
					will permanently remove his/her data and their plans will no longer be
					linked to your organisation.
				</p>
				<p className="confirmation">
					Please type{' '}
					<b>{`${currentEmployee?.firstname} ${currentEmployee?.lastname}`}</b>{' '}
					to confirm deletion.
				</p>
				<Input
					type="text"
					id="name"
					value={name}
					onChange={(e) => setName(e.target.value)}
					// onBlur={handleBlur}
					label="Employee's Name"
					name="name"
					error={
						name.toLowerCase() !=
						currentEmployee?.firstname.toLowerCase() +
							' ' +
							currentEmployee?.lastname.toLowerCase() ? (
							<small className="error">Match Name Before Deleting</small>
						) : null
					}
				/>

				<Button
					variant="danger"
					className="btn--block"
					onClick={() => {
						dispatch(deleteEmployee(currentEmployee?.employeeId));
					}}
					disabled={
						name.toLowerCase() !=
						currentEmployee?.firstname.toLowerCase() +
							' ' +
							currentEmployee?.lastname.toLowerCase()
					}
				>
					{addEmployeeLoading ? 'Deleting....' : 'Delete Employee'}
				</Button>
			</div>
		</Modal>
	);
};

export default DeleteEmployeeModal;
