import React, { useState } from 'react';
import Goback from '../../../components/Goback';
import AddClientsManually from '../../../components/AddClientsManually';
import ImportClients from '../../../components/ImportClients';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorTable } from '../../../components/table/ErrorTable';
import Button from '../../../components/Button';
import { clearErrorUploadingClientCsvFile } from '../../../features/resellers/resellersSlice';
// import Icon from '../../../components/Icon';

const ClinetsInfo = () => {
	const [isImportingClients, setIsImportingClients] = useState(false);
	const dispatch = useDispatch();
	const { errorUploadingClientCsvFile } = useSelector(
		(store) => store.resellers
	);

	return (
		<div className="clients">
			<Goback />
			<div className="clients__header">
				<h4>Client Information</h4>
				<p className="mini-text">
					Add clients information, (Include: first name, last name, phone
					number(optional), email and Client ID)
				</p>
				{errorUploadingClientCsvFile?.data?.faultyClients ? (
					<>
						<div className="error__table mt-24">
							<p>
								File Rejected:{' '}
								{errorUploadingClientCsvFile?.data?.missingMandatoryFields}{' '}
								MISSING MADATORY Field(s)
							</p>
							<ErrorTable
								data={errorUploadingClientCsvFile?.data?.faultyClients}
							/>
						</div>
						<Button
							variant="primary"
							type="submit"
							className={'mt-24'}
							block
							onClick={() => {
								dispatch(clearErrorUploadingClientCsvFile());
								setIsImportingClients(true);
							}}
						>
							re-upload file to proceed
						</Button>
					</>
				) : (
					<>
						<div className="clients__header--pills">
							<div
								className={`clients__header--pill ${
									!isImportingClients ? 'active' : ''
								}`}
								onClick={() => setIsImportingClients(false)}
							>
								<p>Add New Clients </p>
							</div>
							<div
								className={`clients__header--pill ${
									isImportingClients ? 'active' : ''
								}`}
								onClick={() => setIsImportingClients(true)}
							>
								<p>IMPORT CLIENTS</p>
							</div>
						</div>
						<div className="clients__body">
							{!isImportingClients ? <AddClientsManually /> : <ImportClients />}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default ClinetsInfo;
